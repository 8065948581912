var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"section keep-footer-off-page"},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"columns is-centered"},[_c('div',{staticClass:"column is-two-thirds box p-5"},[_c('h1',{staticClass:"mb-5 heading has-text-centered"},[_vm._v("Join Project")]),(_vm.dataIsLoading)?_c('div',{staticClass:"loadable"},[_c('b-loading',{attrs:{"is-full-page":false},model:{value:(_vm.dataIsLoading),callback:function ($$v) {_vm.dataIsLoading=$$v},expression:"dataIsLoading"}})],1):_vm._e(),_vm._l((_vm.projects),function(project,pid){return _c('b-collapse',{key:pid,staticClass:"m-2 ml-5",attrs:{"open":true,"aria-id":"contentIdForA11y1"}},[_c('b-taglist',{attrs:{"slot":"trigger","attached":"","aria-controls":"contentIdForA11y1"},slot:"trigger"},[_c('SmartTag',{staticClass:"my-1",attrs:{"tags":[
                { type: 'is-project', value: project.projectName },
                {
                  type: 'is-role',
                  value: project.roles[_vm.uid],
                },
              ]},nativeOn:{"click":function($event){return _vm.navigateToProject(pid)}}})],1),_vm._l((project.tasks),function(task,tid){return _c('div',{key:tid,staticClass:"pl-5 my-1"},[_c('SmartTag',{staticClass:"is-clickable",attrs:{"size":"is-small","tags":[
                { type: 'is-task', value: task.name },
                { type: 'is-date', value: task.deadlineDate },
                { type: 'is-skill', value: task.skills[0] },
              ]},nativeOn:{"click":function($event){return _vm.navigateToTask(tid)}}})],1)})],2)}),(!_vm.hasProjects)?_c('b-message',{attrs:{"type":"is-info","aria-close-label":"Close message"}},[_c('p',[_vm._v(" You do not have any projects yet. Join an existing project or start your own by clicking below. ")])]):_vm._e(),(_vm.errorInGetRequest)?_c('b-message',{attrs:{"type":"is-danger","aria-close-label":"Close message"}},[_c('p',[_vm._v("Oops something has gone wrong. Please try again later.")])]):_vm._e()],2)]),_c('div',{staticClass:"buttons is-centered mt-6"},[_c('button',{staticClass:"button is-info",on:{"click":function($event){return _vm.$router.push({ name: 'Project_view' })},"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.$router.push({ name: 'Project_view' })}}},[_vm._v(" My Projects ")]),_c('button',{staticClass:"button is-info",on:{"click":function($event){return _vm.$router.push({ name: 'New_project' })},"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.$router.push({ name: 'New_project' })}}},[_vm._v(" Start a Project ")])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }